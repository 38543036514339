@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --topnav-height: 3rem;
}

@media (min-width: 768px) {
  :root {
    --topnav-height: 4rem;
  }
}

/* For linked headers in docs; .icon.icon-link added by rehypeAutolinkHeadings */
.icon.icon-link {
  display: none;
  text-align: right;
  position: absolute;
  right: 100%;
}
.icon.icon-link::before {
  content: "#\00a0";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
}

h1:hover .icon.icon-link,
h2:hover .icon.icon-link,
h3:hover .icon.icon-link,
h4:hover .icon.icon-link,
h5:hover .icon.icon-link,
h6:hover .icon.icon-link,
.icon.icon-link:hover {
  display: inline-block;
}

.toc-outline {
  background: white;
  z-index: 2;
  line-height: 1rem;
  font-size: 0.8rem;
  display: inline-block;
}

.toc-outline:before {
  font-weight: bold;
  content: "Table of contents";
}

.toc-outline ol {
  list-style-type: none;
  margin-top: 0px;
}

ol.toc-level-1 {
  padding: 0px;
}

.toc-outline a {
  color: rgba(111, 59, 232, 0.8);
}

.toc-outline a:hover {
  color: rgba(111, 59, 232, 1);
}

@media screen and (min-width: 992px) {
  .toc-outline {
    position: fixed;
    top: 120px;
    right: 20px;
    max-width: 200px;
  }
}

@media screen and (max-width: 992px) {
  .toc-outline {
    display: none;
  }
}
